// @ts-nocheck
import type { InlineMessageProps } from "primevue/inlinemessage"

export default {
    root: ({ props }: { props: InlineMessageProps }) => ({
        class: [
            "inline-flex items-center justify-center align-top gap2 flex-grow",
            "p2 m0 rounded-md dark:border",
            {
                "bg-sky-100/70 dark:bg-sky-500/20": props.severity == "info",
                "bg-emerald-100/70 dark:bg-emerald-500/20": props.severity == "success",
                "bg-yellow-100/70 dark:bg-yellow-500/20": props.severity == "warn",
                "bg-rose-100/70 dark:bg-rose-500/20": props.severity == "error"
            },
            {
                "border-sky-500 dark:border-sky-400": props.severity == "info",
                "border-emerald-500 dark:border-emerald-400": props.severity == "success",
                "border-yellow-500": props.severity == "warn",
                "border-rose-500 dark:border-rose-400": props.severity == "error"
            },
            {
                "text-sky-700 dark:text-sky-300": props.severity == "info",
                "text-emerald-700 dark:text-emerald-300": props.severity == "success",
                "text-yellow-700 dark:text-yellow-300": props.severity == "warn",
                "text-rose-700 dark:text-rose-300": props.severity == "error"
            }
        ]
    }),
    icon: {
        class: "w5 h5"
    },
    text: {
        class: [
            // Font and Text
            "text-sm leading-none",
            "font-medium"
        ]
    }
};
