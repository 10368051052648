import "virtual:uno.css"
import "@unocss/reset/tailwind.css"

import { createApp } from "vue"
import FloatingVue from "floating-vue"
import { register as registerSwiper } from "swiper/element/bundle"
import { createHead } from "@unhead/vue"
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    Title,
    Tooltip,
    ArcElement,
    PointElement,
    LineElement,
} from "chart.js"

import App from "@/components/App.vue"
import { router } from "@/plugins/router"
import { installPrime } from "@/plugins/prime"

import { createI18n } from "vue-i18n"
import messages from "@intlify/unplugin-vue-i18n/messages"

import { init, browserTracingIntegration, replayIntegration } from "@sentry/vue"
import type { Router } from "vue-router"

function installSentry(app: App, router: Router) {
    init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            browserTracingIntegration({ router }),
            replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/+\.wecker\.cloud.*$/],

        // Capture Replay for 5% of all sessions,
        // plus for 50% of sessions with an error
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 0.5,

        enabled: import.meta.env.PROD
    })
}

export const i18n = createI18n({
    availableLocales: ["cs", "de", "en", "it", "pl", "sk"],
    locale: "en",
    fallbackLocale: "en",
    globalInjection: true,
    legacy: false,
    messages,
})

const app = createApp(App)

app.use(router)
installSentry(app, router)
app.use(i18n)
installPrime(app)
app.use(createHead())
ChartJS.register(
    Title, Tooltip, Legend, Colors, BarElement, CategoryScale, LinearScale,
    ArcElement, PointElement, LineElement,
)
app.use(FloatingVue)
registerSwiper()

app.mount("#app")
