export default {
    root: {
        class: [
            //Shape
            'rounded-xl',
            'shadow-md',

            //Color
            'bg-surface-0 dark:bg-surface-900',
            'text-surface-700 dark:text-surface-0',

            //Border
            'border border-surface-200 dark:border-surface-700',

            //Spacing
            'p-2.5 m-2.5'
        ]
    },
    body: {
        class: 'p-2.5'
    },
    title: {
        class: 'text-2xl font-bold mb-2'
    },
    subtitle: {
        class: [
            //Font
            'font-normal',

            //Spacing
            'mb-2',

            //Color
            'text-surface-600 dark:text-surface-0/60'
        ]
    },
    content: {
        class: 'py-5' // Vertical padding.
    },
    footer: {
        class: 'pt-5' // Top padding.
    }
}
