<script setup lang="ts">
import { ref, onMounted } from "vue"
import "@/assets/styles.css"
import DynamicDialog from "primevue/dynamicdialog"
import { useEmitter, useSupabase, useCounter, useRoute, useHead } from "@/composables"

const route = useRoute()
const emitter = useEmitter()
const supabase = useSupabase()

const isAuthenticated = ref(false)
const isSellerLoaded = ref(false)
const progress = useCounter(0, { min: 0, max: 100 })


useHead({
    script: [
        {
            // Atlassian Statuspage alerts
            key: "statuspage",
            //type: "text/partytown",
            id: "statuspage-script-loader",
            src: "https://wecker.statuspage.io/embed/script.js",
            async: true,
            defer: true,
        },
    ],
})

// 1. we show app skeleton
// 2. we fetch seller context
// 3. we show full app

if (import.meta.env.PROD) useHead({
    script: [
        {
            // Only load HubSpot Chat in production environment
            key: "hubspot-chat",
            //type: "text/partytown",
            id: "hs-script-loader",
            src: "https://js-eu1.hs-scripts.com/143791846.js",
            async: true,
            defer: true,
            //innerHTML: hubspotSrc,
        },
    ],
})

onMounted(async () => {

})
</script>

<template>
    <!--
        Here the `un-all` attr resolves a common minor style issue that causes an ugly black box to appear around
        various elements in Firefox (at the time of writing, version 120) when 3they are focused. Instead we rely
        on the `focus` selector to highlight focused elements using our custom logic.
    -->
    <LoadingScreen v-if="!isAuthenticated" :progress="progress.count.value" />

    <div v-else un-all="focus-within:outline-0" class="bg-base select-none font-sans">
        <div class="antialiased text-black dark:text-white">
            <Header />

            <span>
                <SidebarPrimary />
                <SidebarSecondary />
            </span>

            <main select-text min-h-screen bg-base-dark p="4 t18" md:ml-12 text-secondary-900
                dark="text-secondary-100" overflow-auto>
                <RouterView v-if="isSellerLoaded" v-slot="{ Component }">
                    <template v-if="Component">
                        <Transition name="slide">
                            <KeepAlive :max="10">
                                <Component :is="Component" />
                            </KeepAlive>
                        </Transition>
                    </template>
                </RouterView>
            </main>
        </div>
    </div>
    <Toast position="bottom-right" group="br" />
    <DynamicDialog />
</template>
