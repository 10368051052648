<script setup lang="ts">
import { reactive } from "vue"

const items = reactive([
    {
        text: "Explore your inventory",
        icon: "i-fluent:apps-list-24-filled",
        actionIcon: "i-fluent:arrow-right-24-filled",
        to: "/view/inventory",
    }, {
        text: "Analyze your sales",
        icon: "i-fluent:money-calculator-24-filled",
        actionIcon: "i-fluent:arrow-right-24-filled",
        to: "/view/sales",
    }, {
        text: "Track your orders",
        icon: "i-fluent:box-multiple-search-24-filled",
        actionIcon: "i-fluent:arrow-right-24-filled",
        to: "/view/orders",
    }, {
        text: "Don't miss out on what's happening",
        icon: "i-fluent:mail-alert-24-filled",
        actionIcon: "i-fluent:arrow-right-24-filled",
        to: "/user/inbox",
    }, {
        text: "Visit our help center for guides and more",
        icon: "i-fluent:book-question-mark-24-filled",
        actionIcon: "i-fluent:arrow-up-right-24-filled",
        to: "https://help.wecker.tech/",
    }, {
        text: "Customize your experience",
        icon: "i-fluent:edit-settings-24-filled",
        actionIcon: "i-fluent:arrow-right-24-filled",
        to: "/user/settings",
    },
])
</script>

<template>

<div>

<section bg-white dark:bg-secondary-900 mt-4>
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-primary-500 dark:text-primary-300">
            Welcome to <span font-bold un-text="primary-600 dark:primary-400">SELIN</span>!
        </h2>
        <p class="mb-3 font-light text-secondary-500 dark:text-secondary-400 sm:text-xl">
            Ready to take your seller experience to a new level?
        </p>
        <p class="mb-8 font-light text-secondary-500 dark:text-secondary-400 sm:text-xl">
            Here's some quick links to get you started!
        </p>
        <div class="grid gap-8 lg:grid-cols-2">
            <Link v-for="{ text, icon, actionIcon, to } in items" :key="text + to"
                :to="to" class="inline-flex justify-center items-center p-5 text-base font-medium text-secondary-500 bg-secondary-50 rounded-lg hover:text-secondary-900 hover:bg-secondary-100 dark:text-secondary-400 dark:bg-secondary-800 dark:hover:bg-secondary-700 dark:hover:text-white">
                <i :class="icon" mr3 text-2rem text-secondary-500 dark:text-secondary-200></i>
                <span class="w-full" v-text="text"></span>
                <i :class="actionIcon" ml3 text-2rem text-secondary-500 dark:text-secondary-200></i>
            </Link>
        </div>
    </div>
</section>

</div>

</template>
