import { createRouter, createMemoryHistory, type RouteRecordRaw } from "vue-router"
import WelcomeView from "@/views/WelcomeView.vue"

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: WelcomeView,
    },
]

export const router = createRouter({
    history: createMemoryHistory(),
    routes,
})
