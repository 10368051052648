import type { App } from "vue"
import PrimeVue from "primevue/config"
import AutoComplete from "primevue/autocomplete"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import AnimateOnScroll from "primevue/animateonscroll"
import Avatar from "primevue/avatar"
import AvatarGroup from "primevue/avatargroup"
import Badge from "primevue/badge"
import BadgeDirective from "primevue/badgedirective"
import BlockUI from "primevue/blockui"
// import Button from "primevue/button"
import Breadcrumb from "primevue/breadcrumb"
import Calendar from "primevue/calendar"
import Card from "primevue/card"
import CascadeSelect from "primevue/cascadeselect"
import Checkbox from "primevue/checkbox"
import Chip from "primevue/chip"
import Chips from "primevue/chips"
import ColorPicker from "primevue/colorpicker"
import Column from "primevue/column"
import ColumnGroup from "primevue/columngroup"
import ConfirmDialog from "primevue/confirmdialog"
import ConfirmPopup from "primevue/confirmpopup"
import ConfirmationService from "primevue/confirmationservice"
import ContextMenu from "primevue/contextmenu"
import DataTable from "primevue/datatable"
import DataView from "primevue/dataview"
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions"
import DeferredContent from "primevue/deferredcontent"
import Dialog from "primevue/dialog"
import DialogService from "primevue/dialogservice"
import Divider from "primevue/divider"
import Dock from "primevue/dock"
import Dropdown from "primevue/dropdown"
import DynamicDialog from "primevue/dynamicdialog"
import Fieldset from "primevue/fieldset"
import FileUpload from "primevue/fileupload"
import FocusTrap from "primevue/focustrap"
import Galleria from "primevue/galleria"
import Image from "primevue/image"
import InlineMessage from "primevue/inlinemessage"
import Inplace from "primevue/inplace"
import InputGroup from "primevue/inputgroup"
import InputGroupAddon from "primevue/inputgroupaddon"
import InputSwitch from "primevue/inputswitch"
import InputText from "primevue/inputtext"
import InputMask from "primevue/inputmask"
import InputNumber from "primevue/inputnumber"
import Knob from "primevue/knob"
import Listbox from "primevue/listbox"
import MegaMenu from "primevue/megamenu"
import Menu from "primevue/menu"
import Menubar from "primevue/menubar"
import Message from "primevue/message"
import MeterGroup from "primevue/metergroup"
import MultiSelect from "primevue/multiselect"
import OrderList from "primevue/orderlist"
import OrganizationChart from "primevue/organizationchart"
import OverlayPanel from "primevue/overlaypanel"
import Paginator from "primevue/paginator"
import Panel from "primevue/panel"
import PanelMenu from "primevue/panelmenu"
import Password from "primevue/password"
import PickList from "primevue/picklist"
import ProgressBar from "primevue/progressbar"
import ProgressSpinner from "primevue/progressspinner"
import Rating from "primevue/rating"
import RadioButton from "primevue/radiobutton"
// import Ripple from "primevue/ripple"
import Row from "primevue/row"
import SelectButton from "primevue/selectbutton"
import ScrollPanel from "primevue/scrollpanel"
import ScrollTop from "primevue/scrolltop"
import Skeleton from "primevue/skeleton"
import Slider from "primevue/slider"
import Sidebar from "primevue/sidebar"
import SpeedDial from "primevue/speeddial"
import SplitButton from "primevue/splitbutton"
import Splitter from "primevue/splitter"
import SplitterPanel from "primevue/splitterpanel"
import Steps from "primevue/steps"
import StyleClass from "primevue/styleclass"
import TabMenu from "primevue/tabmenu"
import TieredMenu from "primevue/tieredmenu"
import Textarea from "primevue/textarea"
import Toast from "primevue/toast"
import ToastService from "primevue/toastservice"
import Toolbar from "primevue/toolbar"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Tag from "primevue/tag"
import Terminal from "primevue/terminal"
import Timeline from "primevue/timeline"
import ToggleButton from "primevue/togglebutton"
// import Tooltip from "primevue/tooltip"
import Tree from "primevue/tree"
import TreeSelect from "primevue/treeselect"
import TreeTable from "primevue/treetable"
import TriStateCheckbox from "primevue/tristatecheckbox"
import VirtualScroller from "primevue/virtualscroller"
import Prime from "./preset"


export function installPrime(app: App) {
    app.use(PrimeVue, { ripple: false, unstyled: true, pt: Prime })

    const services = [ConfirmationService, ToastService, DialogService]

    const directives = {
        //"tooltip": Tooltip,
        "badge": BadgeDirective,
        //"ripple": Ripple,
        "styleclass": StyleClass,
        "focustrap": FocusTrap,
        "animateonscroll": AnimateOnScroll
    }

    const components = {
        "Accordion": Accordion,
        "AccordionTab": AccordionTab,
        "AutoComplete": AutoComplete,
        "Avatar": Avatar,
        "AvatarGroup": AvatarGroup,
        "Badge": Badge,
        "BlockUI": BlockUI,
        "Breadcrumb": Breadcrumb,
        // "Button": Button,
        "Calendar": Calendar,
        "Card": Card,
        "CascadeSelect": CascadeSelect,
        "Checkbox": Checkbox,
        "Chip": Chip,
        "Chips": Chips,
        "ColorPicker": ColorPicker,
        "Column": Column,
        "ColumnGroup": ColumnGroup,
        "ConfirmDialog": ConfirmDialog,
        "ConfirmPopup": ConfirmPopup,
        "ContextMenu": ContextMenu,
        "DataTable": DataTable,
        "DataView": DataView,
        "DataViewLayoutOptions": DataViewLayoutOptions,
        "DeferredContent": DeferredContent,
        "Dialog": Dialog, // injected as Modal to avoid conflict with the native dialog element
        "Divider": Divider,
        "Dock": Dock,
        "Dropdown": Dropdown,
        "DynamicDialog": DynamicDialog,
        "Fieldset": Fieldset,
        "FileUpload": FileUpload,
        "Galleria": Galleria,
        "Image": Image,
        "InlineMessage": InlineMessage,
        "Inplace": Inplace,
        "InputGroup": InputGroup,
        "InputGroupAddon": InputGroupAddon,
        "InputMask": InputMask,
        "InputNumber": InputNumber,
        "InputSwitch": InputSwitch,
        "InputText": InputText,
        "Knob": Knob,
        "Listbox": Listbox,
        "MegaMenu": MegaMenu,
        "Menu": Menu,
        "Menubar": Menubar,
        "Message": Message,
        "MeterGroup": MeterGroup,
        "MultiSelect": MultiSelect,
        "OrderList": OrderList,
        "OrganizationChart": OrganizationChart,
        "OverlayPanel": OverlayPanel,
        "Paginator": Paginator,
        "Panel": Panel,
        "PanelMenu": PanelMenu,
        "Password": Password,
        "PickList": PickList,
        "ProgressBar": ProgressBar,
        "ProgressSpinner": ProgressSpinner,
        "RadioButton": RadioButton,
        "Rating": Rating,
        "Row": Row,
        "SelectButton": SelectButton,
        "ScrollPanel": ScrollPanel,
        "ScrollTop": ScrollTop,
        "Slider": Slider,
        "Sidebar": Sidebar,
        "Skeleton": Skeleton,
        "SpeedDial": SpeedDial,
        "SplitButton": SplitButton,
        "Splitter": Splitter,
        "SplitterPanel": SplitterPanel,
        "Steps": Steps,
        "TabMenu": TabMenu,
        "TabView": TabView,
        "TabPanel": TabPanel,
        "Tag": Tag,
        "Textarea": Textarea,
        "Terminal": Terminal,
        "TieredMenu": TieredMenu,
        "Timeline": Timeline,
        "Toast": Toast,
        "Toolbar": Toolbar,
        "ToggleButton": ToggleButton,
        "Tree": Tree,
        "TreeSelect": TreeSelect,
        "TreeTable": TreeTable,
        "TriStateCheckbox": TriStateCheckbox,
        "VirtualScroller": VirtualScroller
    }

    // Install
    services.forEach((s) => app.use(s))
    Object.entries(directives).forEach(([k, v]) => app.directive(k, v))
    Object.entries(components).forEach(([k, v]) => app.component(k, v))
}
