// @ts-nocheck
import type { MessageProps } from "primevue/message/Message"

export default {
    root: ({ props }: { props: MessageProps }) => ({
        class: [
            // Spacing and Shape
            "my-4 mx-0",
            "rounded-md",
            "border-solid border-0 border-l-[6px]",

            // Colors
            {
                "bg-sky-100/70 dark:bg-sky-500/20": props.severity == "info",
                "bg-emerald-100/70 dark:bg-emerald-500/20": props.severity == "success",
                "bg-yellow-100/70 dark:bg-yellow-500/20": props.severity == "warn",
                "bg-rose-100/70 dark:bg-rose-500/20": props.severity == "error"
            },
            {
                "border-sky-500 dark:border-sky-400": props.severity == "info",
                "border-emerald-500 dark:border-emerald-400": props.severity == "success",
                "border-yellow-500": props.severity == "warn",
                "border-rose-500 dark:border-rose-400": props.severity == "error"
            },
            {
                "text-sky-700 dark:text-sky-300": props.severity == "info",
                "text-emerald-700 dark:text-emerald-300": props.severity == "success",
                "text-yellow-700 dark:text-yellow-300": props.severity == "warn",
                "text-rose-700 dark:text-rose-300": props.severity == "error"
            }
        ]
    }),
    wrapper: {
        class: [
            // Flexbox
            "flex items-center",

            // Spacing
            "py3 px-6"
        ]
    },
    icon: {
        class: [
            // Sizing and Spacing
            "w-6 h-6",
            "text-lg leading-none mr-2 shrink-0"
        ]
    },
    text: {
        class: [
            // Font and Text
            "text-base leading-none",
            "font-medium"
        ]
    },
    button: {
        class: [
            // Flexbox
            "flex items-center justify-center",

            // Size
            "w-8 h-8",

            // Spacing and Misc
            "ml-auto  relative",

            // Shape
            "rounded-full",

            // Colors
            "bg-transparent",

            // Transitions
            "transition duration-200 ease-in-out",

            // States
            "hover:bg-surface-0/50 dark:hover:bg-surface-0/10",

            // Misc
            "overflow-hidden"
        ]
    },
    transition: {
        enterFromClass: "opacity-0",
        enterActiveClass: "transition-opacity duration-300",
        leaveFromClass: "max-h-40",
        leaveActiveClass: "overflow-hidden transition-all duration-300 ease-in",
        leaveToClass: "max-h-0 opacity-0 !m-0"
    }
};
