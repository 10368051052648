// @ts-expect-error - library not typed (at all)
import Parser from "ua-parser-js"
import * as jdenticon from "jdenticon"
import { useDark, useToggle } from "@vueuse/core"

export { useEmitter } from "./useEmitter"
export { useSupabase } from "./useSupabase"
export * from "@vueuse/core"
export { useRoute, useRouter, useLink } from "vue-router"
export { useHead, useHeadSafe, useScript, useSeoMeta } from "@unhead/vue"

type Unit = "second" | "minute" | "hour" | "day" | "week" | "month" | "year"

export const useMilliseconds = (unit?: Unit, value: number = 1): number => {
    if (unit === "second") return value * 1000
    else if (unit === "minute") return value * 1000 * 60
    else if (unit === "hour") return value * 1000 * 60 * 60
    else if (unit === "day") return value * 1000 * 60 * 60 * 24
    else if (unit === "week") return value * 1000 * 60 * 60 * 24 * 7
    else if (unit === "month") return value * 1000 * 60 * 60 * 24 * 30
    else if (unit === "year") return value * 1000 * 60 * 60 * 24 * 365
    else return value
}

export const useAgo = (...args: Parameters<typeof useMilliseconds>): Date =>
    new Date(Date.now() - useMilliseconds(...args))

const isDark = useDark()
const toggleDarkMode = useToggle(isDark)

export const useDarkMode = () => ({ isDark, toggleDarkMode })

export const useUserAgent = () => {
    const parser = new Parser()
    return parser.getResult()
}

jdenticon.configure({
    backColor: "#faf4e7",
    replaceMode: "never",
})

export const useJdenticon = () => jdenticon
